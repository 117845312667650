<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Lista uprawnienień</h3>
        </header>
        <Button type="button" label="Dodaj jednostkę" v-on:click="click"/>
        <DataTable :value="content" v-model:filters="filters" :loading="loading" removableSort responsiveLayout="scroll"
                   :globalFilterFields="['name', 'key']">
            <template #header>
                <div class="p-d-flex p-jc-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search"/>
                            <InputText v-model="filters['global'].value" placeholder="Wyszukaj"/>
                        </span>
                </div>
            </template>
            <template #empty>
                Nie znalieziono uprawnień
            </template>
            <template #loading>
                Ładwanie uprawnień. Proszę czekać
            </template>
            <Column field="description" header="Uprawnienie" :sortable="true"></Column>
            <Column field="key" header="Kod" :sortable="true"></Column>
        </DataTable>
    </div>
</template>

<script>
    import PermissionsService from "../../services/permisions.service";
    import {FilterMatchMode} from 'primevue/api';

    export default {
        name: "PermissionsList",
        data() {
            return {
                content: null,
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
                },
                loading: true,
            };
        },
        methods: {
            getList: function () {
                PermissionsService.getAllPermissions().then(
                    (response) => {
                        this.loading = false;
                        this.content = response.data;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
        },
        mounted() {
            this.getList();
        },
    };
</script>
